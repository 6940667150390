export enum NotificationTypeId {
  Coupon = 1,
  Deposit = 2,
  Withdrawal = 3,
  KYC = 4,
  Comission = 5, // bonus
  BonusWithdrawal = 6,
  Follow = 7,
  Bet = 8, // copy bet
  Tournament = 9,
  RollCompetition = 10,
  GameBet = 11,
  Alert = 12,
}
