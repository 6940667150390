import { GenericError } from '@/models/generic-error';
import { ErrorService } from '@/services/ErrorService';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { fetchDailyUserAccess } from './service';
import { setCookie } from 'cookies-next';
import { CookiesKey } from '@starsoft/common/enums';

export function useDailyUserAccess() {
  function onSuccess() {
    const now = new Date();
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1,
    );
    const maxAge = (endOfDay.getTime() - now.getTime()) / 1000;

    setCookie(CookiesKey.DailyUserAccess, true, {
      maxAge,
    });
  }

  function onError(e: AxiosError<GenericError>) {
    ErrorService.onError(e);
  }

  const { mutate, isPending } = useMutation({
    mutationFn: fetchDailyUserAccess,
    onSuccess,
    onError,
  });

  return {
    updateDailyUserAccess: mutate,
    loadingDailyUserAccess: isPending,
  };
}
