import { useDailyUserAccess } from '@/api/daily-user-access';
import { useUserAccount } from '@/api/user';
import { CookiesKey } from '@starsoft/common/enums';
import { hasCookie } from 'cookies-next';
import { useCallback, useEffect } from 'react';

export function useDailyUserAccessHandler() {
  const { loadingDailyUserAccess, updateDailyUserAccess } =
    useDailyUserAccess();
  const { isAuthorized } = useUserAccount();

  const handleUpdateDailyUserAccess = useCallback(() => {
    if (
      hasCookie(CookiesKey.DailyUserAccess) ||
      loadingDailyUserAccess ||
      !isAuthorized
    ) {
      return;
    }

    updateDailyUserAccess();
  }, [loadingDailyUserAccess, updateDailyUserAccess, isAuthorized]);

  useEffect(handleUpdateDailyUserAccess, [handleUpdateDailyUserAccess]);
}
