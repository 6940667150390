import SideBar from '../../SideBar';
import Header from '../../Header';
import { memo, PropsWithChildren, useMemo } from 'react';
import styles from './styles.module.scss';
import publicRoutes from '@/constants/publicRoutes';
import dynamic from 'next/dynamic';
import { usePathname } from '@/hooks/usePathname';
import { useUserAccount } from '@/api/user/queries';
import useAccountsSocket from '@/hooks/sockets/useAccountsSocket';
import useNotificationsSocket from '@/hooks/sockets/useNotificationsSocket';
import useCoinsSocket from '@/hooks/sockets/useCoinsSocket';
import useSupportNotificationSocket from '@/hooks/sockets/support/useSupportNotificationSocket';
import { useDetectDevice } from '@/hooks/useDetectDevice';
import { Setting } from '@starsoft/common/models';
import { useUserTagManagerEvents } from '@/api/user-tag-manager-events';
import MainLayoutModals from './Modals';
import useUnregisterAllServiceWorkers from '@/hooks/useUnregisterSW';
import useRanksSocket from '@/hooks/sockets/useRanksSocket';
import useUserBonusSocket from '@/hooks/sockets/useUserBonusSocket';
import { useGoogleOneTap } from '@/hooks/useGoogleOneTap';
import { useUserEmails } from '@starsoft/common/api';
import { useDailyUserAccessHandler } from '@/hooks/daily-user-access';

const MobileNavigation = dynamic(() => import('../../MobileNavigation'));

function MainLayout({
  children,
  setting,
}: PropsWithChildren<{ setting: Setting }>) {
  useUnregisterAllServiceWorkers();
  useDetectDevice();
  useUserAccount({
    refetchOnWindowFocus: 'always',
  });
  useAccountsSocket();
  useNotificationsSocket();
  useSupportNotificationSocket();
  useCoinsSocket();
  useUserTagManagerEvents();
  useRanksSocket();
  useUserBonusSocket();
  useGoogleOneTap({
    clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    loginUri: `${process.env.NEXT_PUBLIC_BASE_API}/v1/iam/google/callback`,
  });
  useUserEmails();
  useDailyUserAccessHandler();

  const pathname: string = usePathname();
  const shouldRenderSidebar = useMemo(
    () =>
      !publicRoutes.some(route => pathname?.includes(route)) ||
      ['real', 'demo'].includes(
        pathname?.split('/')?.[pathname?.split('/')?.length - 1],
      ),
    [pathname],
  );

  return (
    <>
      <MainLayoutModals setting={setting} />
      {shouldRenderSidebar && (
        <>
          <Header setting={setting} />
          <div className={styles['mobile-only']}>
            <MobileNavigation />
            {/*   <LayoutMobileNavigation /> */}
          </div>
          <div className={styles['desktop-only']}>
            <SideBar />
          </div>
        </>
      )}
      {children}
    </>
  );
}

export default memo(MainLayout);
