
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable react/no-danger */
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import FramerProvider from '@/lib/providers/FramerProvider';
import ThemeProvider from '@/lib/providers/ThemeProvider';
import MainLayout from '@/components/core/MainLayout';
import '@/styles/theme/globals.scss';
import TanstackQueryProvider from '@/lib/providers/TanstackQueryProvider';
import Seo from '@/components/core/Seo';
import { CasinoMetadata } from '@/components/core/Seo/props';
import dynamic from 'next/dynamic';
import { platformAssets } from '@/utils/assets';
import { wrapper } from '@/lib/store';
import { setSettings } from '@/lib/store/theme/actions';
import PackageCommonSettingsProvider from '@/lib/providers/PackageCommonSettingsProvider';
import { HydrationBoundary } from '@tanstack/react-query';
import Head from 'next/head';
import { useDeferredStyles } from '@/hooks/seo/useDeferredStyles';
const NotificationToasty = dynamic(() => import('@/components/Notifications/NotificationToasty'), { ssr: false });
function MainApp({ Component, ...rest }: any) {
    const { store, props } = wrapper.useWrappedStore(rest);
    useDeferredStyles(props?.pageProps?.settings?.fontFamily?.url);
    if (props?.pageProps?.settings) {
        store.dispatch(setSettings(props?.pageProps?.settings));
    }
    return (<main>
      {platformAssets.tracking?.GTM?.[1] && (<Head>
          <script dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://sgtm.ice.game/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${platformAssets.tracking.GTM[1]}')`,
            }}/>
        </Head>)}
      <Head>
        <Head>
          <noscript>
            <link rel="icon" href="/favicon.png"/>
            <link href={props?.pageProps?.settings?.fontFamily?.url ||
            'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'} rel="stylesheet"/>
            <link rel="stylesheet" href={`${process.env.NEXT_PUBLIC_CF_DISTRIBUTION}/fonts/FontAwesome/all.css`}/>
            <link rel="stylesheet" href={`${process.env.NEXT_PUBLIC_CF_DISTRIBUTION}/fonts/casino-icons.css`}/>
          </noscript>
        </Head>
      </Head>
      <FramerProvider>
        <TanstackQueryProvider>
          <HydrationBoundary state={props?.pageProps?.dehydratedState}>
            <PackageCommonSettingsProvider>
              <ThemeProvider>
                <CssBaseline />
                {!!props?.pageProps?.metadata && (<Seo metadata={props?.pageProps?.metadata as CasinoMetadata}/>)}
                <NotificationToasty />
                <MainLayout setting={props?.pageProps?.settings}>
                  <Component {...props?.pageProps} suppressHydrationWarning/>
                </MainLayout>
              </ThemeProvider>
            </PackageCommonSettingsProvider>
          </HydrationBoundary>
        </TanstackQueryProvider>
      </FramerProvider>
    </main>);
}
const __Next_Translate__Page__1948b093be4__ = wrapper.withRedux(MainApp);

    export default __appWithI18n(__Next_Translate__Page__1948b093be4__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  