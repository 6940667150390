import { AudioService } from '@/services/AudioService';
import {
  DiceActions,
  DiceBetAmountPayload,
  DiceBetVerifyAmountPayload,
  DiceEnterBetFailPayload,
  DiceEnterBetPayload,
  DiceEnterBetSuccessPayload,
  DiceGetMultipliersSuccessPayload,
  DiceHandleChangeMultiplier,
  DiceHandleWinChance,
  DiceProps,
} from './props';
import { DiceSide } from '@/models/bet/dice/side';

const initialState: DiceProps = {
  loading: false,
  betLoading: false,
  error: false,
  bet: null,
  amount: '',
  animate: false,
  winChance: 49,
  multiplier: 50,
  diceSide: DiceSide.Up,
  multipliers: {},
  profitMultiplier: 0,
  currentPosition: 0,
  lastPosition: null,
  round: null,
};

export default function reducer(
  state: DiceProps = initialState,
  action: any,
): DiceProps {
  switch (action.type) {
    case DiceActions.GetAmount:
      return {
        ...state,
        loading: false,
        amount: (action as DiceBetAmountPayload).payload.amount,
      };
    case DiceActions.VerifyAmount: {
      if (Number((action as DiceBetVerifyAmountPayload).payload.amount) < 0) {
        return {
          ...state,
          amount: '10',
        };
      }
      return state;
    }
    case DiceActions.EnterBet:
      return {
        ...state,
        betLoading: true,
        multiplier: (action as DiceEnterBetPayload).payload.multiplier,
      };
    case DiceActions.EnterBetSuccess:
      if (!AudioService.isGamesMuted()) {
        AudioService.diceWin();
      }

      return {
        ...state,
        lastPosition: state.currentPosition,
        currentPosition: Number(
          (action as DiceEnterBetSuccessPayload).payload.bet?.diceBet?.roll,
        ),
        bet: (action as DiceEnterBetSuccessPayload).payload.bet,
        animate: true,
      };
    case DiceActions.EnterBetError:
      return {
        ...state,
        betLoading: false,
        error: (action as DiceEnterBetFailPayload).payload,
      };
    case DiceActions.GetMultipliers: {
      return {
        ...state,
        loading: true,
      };
    }
    case DiceActions.HandleChangeMultiplier: {
      return {
        ...state,
        multiplier: (action as DiceHandleChangeMultiplier).payload.multiplier,
        winChance:
          state.diceSide == DiceSide.Up
            ? 99 - (action as DiceHandleChangeMultiplier).payload.multiplier
            : (action as DiceHandleChangeMultiplier).payload.multiplier,
        profitMultiplier:
          state.multipliers[
            (action as DiceHandleChangeMultiplier).payload.multiplier
          ] ?? 0,
      };
    }
    case DiceActions.HandleChangeWinChance:
      const winChance = +(+(action as DiceHandleWinChance).payload
        .winChance).toFixed(4);
      return {
        ...state,
        winChance: (action as DiceHandleWinChance).payload.winChance,
        multiplier: state.diceSide == DiceSide.Up ? 99 - winChance : winChance,
        profitMultiplier: +(state.multipliers[99 - winChance] ?? 0)?.toFixed(4),
      };
    case DiceActions.HandleChangeDiceSide:
      return {
        ...state,
        diceSide: state.diceSide == DiceSide.Up ? DiceSide.Down : DiceSide.Up,
        multiplier:
          state.diceSide == DiceSide.Up
            ? 99 - state.multiplier
            : 99 - +state.winChance,
      };
    case DiceActions.HandleAnimate:
      return {
        ...state,
        animate: false,
      };
    case DiceActions.GetMultipliersSuccess:
      return {
        ...state,
        multipliers: (action as DiceGetMultipliersSuccessPayload).payload
          .multipliers,
        winChance:
          state.diceSide == DiceSide.Up
            ? 99 - state.multiplier
            : state.multiplier,
        profitMultiplier:
          (action as DiceGetMultipliersSuccessPayload).payload.multipliers[
            state.multiplier
          ] ?? 0,
      };

    default:
      return state;
  }
}
