import { forwardRef, PropsWithChildren } from 'react';
import { ButtonProps } from './props';
import styles from './styles.module.scss';

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      visible = true,
      children,
      disabled,
      isloading,
      isSecondary,
      isSmall,
      isSkeleton,
      isErrorButton,
      isTiny,
      className = '',
      isLinkButton,
      useMaxContent,
      icon,
      skeletonDefault,
      style,
      dotted,
      isTransparent,
      ...props
    },
    ref,
  ) => {
    if (!visible) {
      return null;
    }

    if (isSkeleton) {
      return (
        <div
          className={`${skeletonDefault ? `${styles['button__skeleton-default']} ${isSmall ? styles['button__skeleton-default--isSmall'] : ''}` : `${styles.button__skeleton} ${isSmall ? styles['button__skeleton--isSmall'] : ''}`} ${className}`}
        />
      );
    }

    return (
      <button
        {...props}
        ref={ref} // pass the ref here
        className={`${styles.button} ${isSecondary ? styles['button--secondary'] : ''} ${isSmall ? styles['button--isSmall'] : ''} ${isTiny ? styles['button--isTiny'] : ''} ${isErrorButton ? styles['button--isErrorButton'] : ''} ${isLinkButton ? styles['button--isLinkButton'] : ''} ${useMaxContent ? styles['button--useMaxContent'] : ''} ${dotted ? styles['button--dotted'] : ''} ${isTransparent ? styles['button--transparent'] : ''} ${className ? className : ''}`}
        disabled={isloading || disabled}
        style={style}
      >
        {(icon || isloading) && (
          <i
            className={`${isloading ? `${styles.button__spinner} fas fa-circle-notch fa-spin` : ''} ${icon && !isloading ? icon : ''} `}
          />
        )}
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
