import { useQuery } from '@tanstack/react-query';
import { RollCompetitionsQueryKeys } from '../../queryKey';
import { GenericError } from '@/models/generic-error';
import { AxiosError } from 'axios';
import { fetchRollCompetitions } from './service';
import { RollCompetition } from '@/models/roll-competition';

export function useRollCompetitions() {
  const queryKey = [RollCompetitionsQueryKeys.Primary];

  const { isPending, fetchStatus, data, error, refetch, isFetching } = useQuery<
    RollCompetition,
    AxiosError<GenericError>,
    RollCompetition,
    typeof queryKey
  >({
    queryKey,
    queryFn: fetchRollCompetitions,
    staleTime: 5 * 1000,
  });

  return {
    isPending: isPending && fetchStatus !== 'idle',
    rollCompetition: data,
    error,
    refetch,
    isFetching,
  };
}
