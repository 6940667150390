import { useUserAccount } from '@/api/user';
import styles from './styles.module.scss';
import {
  useLottiePlayer,
  useModalNavigation,
  useTranslation,
} from '@starsoft/common/hooks';
import { useCallback } from 'react';
import { ModalsKey } from '@/enums/modalsKey';
import { useRollCompetitions } from '@/api/roll-competitions';

export default function RollPointGameButton() {
  const { t } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { navigateToModal } = useModalNavigation();
  const { isPending, rollCompetition } = useRollCompetitions();

  const { lottieAnimationRef } = useLottiePlayer({
    path: '/roll-competition-egg.json',
  });

  const handleOpenRollCompetitionModal = useCallback(() => {
    if (!isAuthorized) {
      navigateToModal(ModalsKey.Login);
      return;
    }

    navigateToModal(ModalsKey.RollCompetition);
  }, [isAuthorized, navigateToModal]);

  if (!isPending && !rollCompetition?.cycle) {
    return null;
  }

  return (
    <div
      className={styles.container}
      style={{
        //@ts-ignore
        '--frame': `url(${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/roll-competition/frame-roll.png)`,
        '--frame-bg': `url(${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/roll-competition/background-roll.png)`,
      }}
      onClick={handleOpenRollCompetitionModal}
    >
      <div className={styles.container__wrapper}>
        <div
          ref={lottieAnimationRef}
          className={styles.container__wrapper__lottie__placeholder}
        />
        <div
          ref={lottieAnimationRef}
          className={styles.container__wrapper__lottie}
        />
        <div className={styles.container__wrapper__column}>
          <h5 className={styles.container__wrapper__column__title}>
            {t('roll_competition_title')}
          </h5>
          <p className={styles.container__wrapper__column__description}>
            {t('roll_competition_button_description')}
          </p>
        </div>
      </div>
    </div>
  );
}
