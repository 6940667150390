import SidebarHeader from './Header';
import BonusButton from './BonusButton';
import styles from './styles.module.scss';
import { useShowNavigation } from '@/hooks/useShowNavigation';
import useLayoutSettings from '@/hooks/theme/useLayoutSettings';
import SidebarHeaderContentTabs from './Header/Content/Tabs';
import RewardsSection from './Content/RewardsSection';
import SidebarContent from './Content';
import RollPointGameButton from '../RollCompetition/Button';

function SideBar() {
  const { isCollapsed, isV2LayoutVariant } = useLayoutSettings();
  const showNaviation = useShowNavigation();

  return (
    <div
      className={`${styles.container} ${!showNaviation ? styles['container--hidden'] : ''} ${isV2LayoutVariant ? styles['container--v2'] : ''}`}
    >
      <aside
        className={`${styles.container__aside} ${isCollapsed ? styles['container__aside--collapsed'] : ''}`}
      >
        <div className={styles['container__desktop_only']}>
          <SidebarHeader />
        </div>

        <section
          className={`${styles.container__content} ${isCollapsed ? styles['container__content--collapsed'] : ''}`}
        >
          {!isCollapsed && (
            <>
              <BonusButton />
              <div className={styles.container__spacer} />
              <RewardsSection />
              <div className={styles.container__spacer} />
              <RollPointGameButton />
            </>
          )}

          {isCollapsed && isV2LayoutVariant && (
            <>
              <SidebarHeaderContentTabs showCollapsed />
              <div className={styles.container__spacer} />
            </>
          )}

          <SidebarContent />
        </section>
      </aside>
    </div>
  );
}

export default SideBar;
