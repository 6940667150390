import { BaseContent } from '..';
import useTranslation from 'next-translate/useTranslation';
import { RollCompetitionNotification } from '@/models/notification/notification';
import { NotificationContentProps } from '../props';
import { RollCompetitionExtraParams } from '@/models/notification/notificationExtraParams';
import { WalletService } from '@starsoft/common/services';
import NotificationCoin from '../../Coin';
import { CoinType } from '@starsoft/common/models';

export default function RollCompetitionNotificationContent({
  notification,
  type,
}: NotificationContentProps<RollCompetitionNotification>) {
  const { t } = useTranslation('notify');
  const extra: RollCompetitionExtraParams = notification.extra;

  return (
    <BaseContent
      icon="fa-solid fa-trophy-star"
      title={t('roll_competition_title')}
      description={t('tournament_description', {
        place: extra.place,
        prize: WalletService.maskCurrency({
          coin: extra.coin,
          amount: extra.prize,
          hideSymbol: extra.coin.type === CoinType.Fiat,
        }),
      })}
      components={{
        coin: <NotificationCoin coin={extra.coin} />,
      }}
      type={type}
      createdAt={notification?.createdAt ?? ''}
      notificationTypeId={notification?.type?.id}
      extra={notification?.extra}
    />
  );
}
