import baseAPI from '@/api';
import { AxiosResponse } from 'axios';
import { RollCompetition } from '@/models/roll-competition';

export async function fetchRollCompetitions(): Promise<RollCompetition> {
  const response: AxiosResponse<RollCompetition> =
    await baseAPI.get<RollCompetition>(`/roll-competitions`);

  return response.data;
}
